.cartdata{
    height: 100%;
}
.new-cartdata-product{
   
    width: 450px;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
height: auto;
background-color: aliceblue;

overflow-x: hidden;
padding-right: 50px;
overflow-y: hidden;
padding-left: 10px;
align-items: start;
height: 80px;
margin-bottom: 2px;
}

.cart__bottom{
    cursor: pointer;
}
.cartdata-product-left{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.cartdata-product-left-div1{
width: 35%;
}
.cartdata-product-left-div2{
    width: 70%;
}
.cartdata-product-right{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.cartdata-product-right-item{
    flex: 1;

display: flex;
justify-content: center;
align-items: center;

}
.cartdata-product-right-item button{
width: 30px;
font-size: 11px;
display: flex;
justify-content: center;
align-items: center;
background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
} 
@media screen and(max-width:460px) {

    .new-cartdata-product{
   
    width: 360px;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
height: auto;
background-color: aliceblue;

overflow-x: hidden;
padding-right: 50px;
overflow-y: hidden;
padding-left: 10px;
align-items: start;
height: 60px;
margin-bottom: 2px;
}

.cart__bottom{
    cursor: pointer;
}
.cartdata-product-left{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.cartdata-product-left-div1{
width: 35%;
}
.cartdata-product-left-div2{
    width: 70%;
}
.cartdata-product-right{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.cartdata-product-right-item{
    flex: 1;

display: flex;
justify-content: center;
align-items: center;

}
.cartdata-product-right-item button{
width: 30px;
font-size: 11px;
display: flex;
justify-content: center;
align-items: center;
background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
} 


}

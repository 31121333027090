.footer {
  padding-top: 20px;
  padding-bottom: 30px;
  /* background: #ffebd7df; */
  background-color: black;
}

.footer__logo img {
  width: calc(100% - 70%);

}

.footer__logo h5 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer__logo p {
  font-size: 0.9rem;
  /* color: #333; */
  color: white;
  line-height: 28px;
}

.delivery__time-item {
  background: transparent !important;
}

.delivery__time-item span {
  font-weight: 600;
  font-size: 1rem;
  /* color: #212245; */
  color: white;
}

.delivery__time-item p {
  /* color: #212245; */
  color: white;
}
.footer__title {
  font-size: 1.1rem;
  /* color: #212245; */
  color: white;
  font-weight: 600;
}

.newsletter {
  padding: 5px;
  /* border: 1px solid #212245; */
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
}
.newsletter input {
  background: transparent;
  border: none;
  outline: none;
}

.newsletter input:focus {
  outline: none;
}

.newsletter span {
  background: #ee0808;
  padding: 5px 30px;
  border-radius: 5px;
}

.newsletter span i {
  color: #fff;
}

.newsletter input::placeholder {
  /* color: #212245; */
  color: white;
}

.social__links span i {
  padding: 5px;
  background: #df2020;
  color: #fff;
  border-radius: 50%;
}

.whatsappicon{
  color: wheat;
}

.copyright__text {
  font-size: 0.9rem;
  font-weight: 600;
  /* color: rgb(21, 21, 90); */
  color: white;
}
.followtext{
  color: white;
}
@media only screen and (max-width: 992px) {
  .newsletter input {
    padding: 7px;
    font-size: 0.7rem;
  }
  .newsletter span {
    padding: 4px 20px;
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .footer__title {
    font-size: 0.8rem;
  }

  .delivery__time-item span {
    font-size: 0.8rem;
  }

  .delivery__time-item p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .copyright__text {
    text-align: center;
  }

  .social__links p {
    font-size: 0.8rem;
  }

  .social__links span {
    padding: 4px;
  }
  .social__links span a {
    font-size: 0.7rem;
  }
}


.text-container {
  background-color: black;

}

.text-container h1 {
  font-family: 'Arial Black', sans-serif;
  color: #ffe100; /* Gold color */
  font-size: 18px;
  text-align: start;
padding-top: 10px;
}

.text-container p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff; /* White color */
  font-size: 18px;
  text-align: center;
  line-height: 1;

}
.svgicon{
  height: 50px;
  width: 50px;
  background-image: url('../../assets/restaurant-plate-svgrepo-com\ \(1\).svg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}
.mid-div{
  display: flex;
  align-items: center;

}

.newsletter2 {
  padding: 5px;
  /* border: 1px solid #212245; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
  width: 100%;
}
.newsletter2 input {
  background: transparent;
  border: none;
  outline: none;
}

.newsletter2 input:focus {
  outline: none;
}

.newsletter2 span {
  background: #ee0808;
  padding: 5px 30px;
  border-radius: 5px;
}

.newsletter2 span i {
  color: #fff;
}

.newsletter2 input::placeholder {
  /* color: #212245; */
  color: white;
}.newsletter input {
  background: transparent;
  border: none;
  outline: none;
  color: white; /* Set text color to white */
}
.header {
  width: 100%;
  height: 100px;
  line-height: 100px;
}

.logo {
  text-align: center;
}
.logo img {
 width: 80%;
 height: 80%;
  object-fit: fill;

}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-top: -40px;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  transition: 0.3s;
  text-decoration: none;
}
/* external.css */
.menu {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
}

.menu a:hover {
  color: #df2020;
}
.header-a{
  color: black;
  text-decoration: none;
  font-weight: 600;
}
 .header-a:hover {
  color: #df2020;
  cursor: pointer;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon i,
.user i,
.mobile__menu i {
  font-size: 1.3rem;
  color: #212245;
  cursor: pointer;
}

.cart__icon {
  position: relative;
}
.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

.header__shrink {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 5px 5px 15px -5px #fde4e4;
}

@media only screen and (max-width: 992px) {

  .logo img {
    width: calc(100% - 70%);
  }
  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }
  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
    padding-top: 10vh;
  }
  .menu a {
    font-weight: 600;
    font-size: 1rem;
    color: #212245;
    transition: 0.3s;
    text-decoration: none;
  }
  .menu a {
    font-size: 0.9rem;
    text-decoration: none;
  }

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
  }
  .logo h5 {
    margin-top: -55px;
  }
  .logo img {
    width: 50%;
    height: 50%;
     object-fit: fill;
   }
}


.user-logout{
  cursor: pointer;
}
.user-logout:hover{
  color: #df2020;
  font-weight: 600;
}
.whatsapp-icon {
  color: #25D366;
  font-size: 24px;
}
.cart__icon {
  text-decoration: none; /* Remove underline */
}

.nav__right a:hover {
padding-bottom: 5px;
  text-decoration: underline #df2020 solid 2px; /* orange underline, solid line, 2px thickness */
}
 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slick-prev:before,
  .slick-next:before{
    color: black;
    z-index: 5;
  }
 /* Common Button */
.order_now {
  color: black;
  background: yellow;
  border-color:yellow;
  padding: 15px 38px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: var(--oswald-font);
  vertical-align: middle;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.order_now:hover,
.order_now:focus {
  color: white;
  background: transparent;
  border-color: white;
}

.btn_red {
  color: white;
  background: rgba(255, 0, 0, 0.87);
  border-color: rgba(255, 0, 0, 0.87);;
}
.btn_red:hover,
.btn_red:focus {
  color: rgba(255, 0, 0, 0.87);;
  background: transparent;
  border-color: rgba(255, 0, 0, 0.87);;
}
/* index.css */

/* Import other stylesheets */
@import "bootstrap/dist/css/bootstrap.css";
@import "remixicon/fonts/remixicon.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* Custom styles for ToastContainer */
.Toastify__toast-container {
  width: 450px; /* Set the desired width */
  height: 50px; /* Set the desired height or use 'auto' for dynamic height */
  /* Add any other custom styles here */
}

/* Custom styles for toast body (data) */
.Toastify__toast-body {
  width: 350px; /* Set data width to 80% */
  float: right; /* Float left to align to the left */
}

/* Custom styles for toast close button container */
.Toastify__close-button-container {
  width: 10%; /* Set button container width to 20% */
  float: right; /* Float right to align to the right */
}

